// Emoji Picker
.emoji-mart{
  position: absolute;
  bottom: 35px;
  right: 0;
}
.emoji-mart-preview{
  display: none;
}

.copyclipboard-alert {
  position: absolute !important;
  bottom: 0;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}


.dropdown-toggle::after{
  display: none !important;
}

.dropdown-menu{
  background-color: $white;
}

.chat-welcome-section {
  display: flex;
  width: 100%;
  height: 100vh;
  align-items: center;
  justify-content: center;
  position: relative;
  background-color:  var(--bs-card-bg);
  @media (max-width: 991.98px) {
    display: none;
  }
}

// Reply Msg
.send-msg{
  position: relative;
  z-index: 2;
}
.replyCard {
  position: absolute;
  left: 0;
  right: 0;
  border-top: 1px solid #e9ebec;
  border-top: 1px solid var(--vz-border-color);
  overflow: hidden;
  opacity: 0;
  bottom: 0;
  border-radius: 0;
  transition: all 0.4s;
  z-index: 1;
  background-color: $white;
  box-shadow: 0px -2px 5px 0px rgba(34,51,153,0.1);
}
.replyCard.show {
  -webkit-transform: translateY(-104px);
  transform: translateY(-104px);
  opacity: 1;
}
.replymessage-block {
  padding: 12px 20px;
  margin-bottom: 8px;
  text-align: left;
  border-radius: 4px;
  background-color: rgba(114,105,239,0.2);
  border-left: 2px solid rgba(114,105,239,1);
}
.right .replymessage-block {
  background-color: rgba(245, 247, 251, 0.2);
  border-left: 2px solid rgba(81, 138, 201,1);
  border-top: 1px solid rgba(114, 105, 239, 0.2);
}
.attachmentCard {
  position: absolute;
  left: 0;
  right: 0;
  border-top: 1px solid #e9ebec;
  border-top: 1px solid var(--vz-border-color);
  overflow: hidden;
  opacity: 0;
  bottom: 0;
  border-radius: 0;
  transition: all 0.4s;
  background-color: $white;
  box-shadow: 0px -2px 5px 0px rgba(34, 51, 153, 0.1);
}
.attachmentCard.show {
  -webkit-transform: translateY(-104px);
  transform: translateY(-104px);
  opacity: 1;
}
.attachmentmessage-block {
  padding: 12px 20px;
  margin-bottom: 8px;
  text-align: left;
  border-radius: 4px;
  background-color: rgba(255,255,255);
  border-left: 2px solid rgb(114, 105, 239, .2);
  border-top: 2px solid rgb(114, 105, 239, .2);
}
.settingComponent {
  .accordion-item {
    box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.1) !important;
    border-color: #fefeff !important;
  }
  .accordion-button {
    background-color: #fefeff;
  }
}

// Dark - Light Mode
body[data-layout-mode="dark"] {
  .layout-mode-dark {
    display: none;
  }
  .layout-mode-light {
    display: inline-block;
  }
  .chat-list li,
  .contact-list li{
    background-color: var(--bs-gray-300) !important;
  }
  .chat-list li.active a,
  .chat-list li a:hover,
  .contact-list li.active{
    background-color: var(--bs-card-bg) !important;
  }
  #users-chat {
    background-color: var(--bs-card-bg) !important;
  }
  .dropdown-menu{
    background-color: var(--bs-gray-300) !important;
    color: $white !important;
  }
  .text-muted,
  .text-primary,
  .form-label,
  .form-check-label{
    color: $white !important;
  }
  .chat-conversation {
    .conversation-list {
      .ctext-wrap-content {
        background-color: var(--bs-gray-300) !important;

        &:before {
          border-left-color: var(--bs-gray-300) !important;
          border-top-color: var(--bs-gray-300) !important;
        }

        .forwardedMessage {
          color: rgba(255, 255, 255, 0.5) !important;
        }
      }
    }
    .right {
        .conversation-list {
        .ctext-wrap-content {
          background-color: $primary !important;

          &:before {
            border-right-color: $primary !important;
            border-left-color: transparent !important;
            border-top-color: $primary !important;
          }
        }
      }
    }
  }
  .ri-arrow-drop-down-line{
    color: var(--bs-gray-600) !important;
  }
  .search-box{
    background-color: var(--bs-body-bg);
  }
  .avatar-title{
    color: $white !important;
  }
  .replyCard,
  .attachmentCard {
    background-color: var(--bs-gray-300) !important;
  }
  .right .replymessage-block,
  .attachmentmessage-block {
    background-color: rgba(245, 247, 251, 0.2);
    border-left: 2px solid rgba(245, 247, 251,1);
    color: $white;

    h5{
      color: $white;
    }
  }
  .ri-close-line{
    color: $white !important;
  }
  .replymessage-block {
    background-color: var(--bs-input-check-border);
    border-left: 2px solid rgba(81, 138, 201,1);
  }
  .dialer{
    background-color: var(--bs-gray-300) !important;

    .digit{
      color: $white;
    }
    .call-icn{
      i{
        color: $white !important;
      }
    }
  }
  .settingComponent {
    .accordion-item {
      box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.1) !important;
      border-color: var(--bs-gray-300) !important;
    }
    .accordion-button {
      background-color: var(--bs-gray-300);
    }
  }
  .newUserComponent{
    background-color: var(--bs-gray-300) !important;
    .mdc-text-field{
      background-color: var(--bs-body-bg);
    }
    label,
    input{
      color: $white !important;
    }
  }
  .offcanvas.show,
  .modal-content{
    background-color: $gray-800;
  }

  .cardAttachment{
    background-color: var(--bs-border-color-translucent) !important;
  }
}
.layout-mode-light {
  display: none;
}
.offcanvas.show{
  visibility: visible;
}
