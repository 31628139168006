/* You can add global styles to this file, and also import other style files */

/* Importing Bootstrap SCSS file. */
// @import '~bootstrap/scss/bootstrap';
//
@import '~@angular/material/prebuilt-themes/indigo-pink.css';


.success-snackbar.mat-mdc-snack-bar-container {
  .mdc-snackbar__surface {
    background:  #518ac9 !important;

    .mat-mdc-button {
      color: white !important;
    }
  }

  .mat-mdc-snack-bar-label.mdc-snackbar__label {
    color: white;
    font-weight: 500;
    white-space: pre-wrap;
    font-family: sans-serif;
  }
}


.error-snackbar.mat-mdc-snack-bar-container {
  .mdc-snackbar__surface {
    background:  #d93535 !important;

    .mat-mdc-button {
      color: white !important;
    }
  }

  .mat-mdc-snack-bar-label.mdc-snackbar__label {
    color: white;
    font-weight: 500;
    white-space: pre-wrap;
    font-family: sans-serif;
  }
}

